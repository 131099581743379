<template>
  <div class="open-year-serve-dialog">
    <el-dialog
      :title="type? '开启常年服务':'关闭常年服务'"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      width="460px"
    >
    <div class="content-box">
      <p style="font-size: 14px; margin-bottom: 10px;">选择服务时间：</p>
      <el-date-picker
        v-model="dateVal"
        :clearable="false"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
    </div>
      <!-- <p @click="linkUrl" class="link-p">{{info.path}}</p> -->
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">确 定</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "openYearServeDialog",
  components: {},
  props: {
    date: {
      type: Array,
    },
  },
  data() {
    return {
      type: true,
      dialogVisible: true,
      myInfo: null,
      dateVal: [],
    };
  },
  created() {
    // console.log(this.date);
    if (this.date.length > 0) {
      this.dateVal = [
        this.date[0],this.date[1]
      ]
    }
  },
  mounted() {},
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          // done();
          this.$parent.showServeDialog = false;
        })
        .catch((_) => {});
    },
    cancel() {
      this.$parent.showServeDialog = false;
    },
    submit() {
      let info = {
        type: !this.type,
        dateVal: this.dateVal,
      }
      this.$emit('onYearServe',info);
    },
  },
};
</script>

<style lang="less" scoped>
.open-year-serve-dialog {
  /deep/ .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #EAF5FF;
        span {
          color: #6474C7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            white-space: nowrap;
          }
          .el-input {
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #D7DBDA;

            }
          }
        }
        .link-p {
          cursor: pointer;
        }
        .btns-box {
          display: flex;
          justify-content: center;
          // justify-content: flex-end;
          margin-top: 20px;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #F1F2F1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="add-task">
    <div class="client-box">
      <p class="all-crumbs" v-if="!stepShow">项目管理 > 我的客户</p>
      <div class="row-box">
        <div class="col-box">
          <p class="label">
            <span>* </span>
            客户名称
          </p>
            <!-- :disabled="form.name.status" -->
          <el-input
            type="text"
            v-model="form.name.val"
            placeholder="请输入客户名称"
          />
        </div>
        <div class="col-box">
          <p class="label">
            <span>* </span>
            联系方式
          </p>
          <el-input
            type="text"
            v-model="form.phone.val"
            placeholder="请输入联系方式"
          />
        </div>
      </div>
      <div class="row-box">
        <div class="col-box">
          <p class="label">客户编号</p>
          <el-input
            type="text"
            v-model="form.code.val"
            placeholder="请输入客户编号"
          />
        </div>
        <div class="col-box">
          <p class="label">客户邮箱</p>
          <el-input
            type="text"
            v-model="form.email.val"
            placeholder="请输入客户邮箱"
          />
        </div>
      </div>
      <div class="row-box">
        <div class="col-box">
          <p class="label">
            <span>* </span>
            所在城市
          </p>
          <!-- <el-input
            type="text"
            v-model="form.provinceCity.val"
            placeholder="请输入所在城市"
            :disabled="form.provinceCity.status"
          /> -->
          <!-- <el-cascader
          v-model="value8"
          :options="provinceCityList"
          :props="{ expandTrigger: 'hover' }"
          @change="handleChange"></el-cascader> -->
          <div class="city-box">
            <el-select
              v-model="provinceVal"
              filterable
              placeholder="省/直辖市/自治区"
              @change="selectProvince"
            >
              <el-option
                v-for="item in provinceList"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="cityVal"
              filterable
              placeholder="市"
              @change="selectCity"
            >
              <el-option
                v-for="item in cityInfo[provinceVal]"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="col-box">
          <p class="label">
            <span>* </span>
            详细地址
          </p>
          <el-input
            type="text"
            v-model="form.address.val"
            placeholder="请输入地址"
          />
        </div>
      </div>
      <div class="row-box">
        <div class="col-box">
          <p class="label">
            <span>* </span>
            客户类型
          </p>
          <el-select
            v-model="form.type.val"
            filterable
            placeholder="请选择"
            @change="selectType"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-box" v-if="form.type.val == 2">
          <p class="label">
            <span>* </span>
            所属行业
          </p>
          <el-select
            v-model="form.industry.val"
            filterable
            placeholder="请选择所属行业"
          >
            <el-option
              v-for="item in industryList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- <div class="col-box" v-else>
          <p class="label">
            <span>* </span>
            客户标签
          </p>
          <el-select
            v-model="form.label.val"
            filterable
            :disabled="form.label.status"
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="item in labelList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div> -->
      </div>
      <div class="row-box" v-if="form.type.val == 2">
        <div class="col-box">
          <p class="label">
            <span>* </span>
            客户性质
          </p>
          <el-select
            v-model="form.nature.val"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in natureList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- <div class="col-box">
          <p class="label">
            <span>* </span>
            客户标签
          </p>
          <el-select
            v-model="form.label.val"
            filterable
            :disabled="form.label.status"
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="item in labelList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div> -->
      </div>
      <!-- <div class="row-box">
        <div class="col-box">
          <p class="label" style="margin-right: 6px;">
            <span>* </span>
            年度法律顾问
          </p>
          <el-radio-group v-model="radio">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </div>
      </div> -->
    </div>
    <div class="tabel-box">
      <div class="table">
        <p
          v-for="item in tableList"
          :key="item"
          :class="tableVal == item ? 'in-p' : ''"
          @click="sheckTable(item)"
        >
          {{ item }}
        </p>
      </div>
      <div class="cards-box" v-show="tableVal == '联系人'">
        <div class="card" v-for="(item, index) in contacts" :key="item.name">
          <div class="row">
            <p class="label">联系人：</p>
            <p class="val">{{ item.name }}</p>
            <p
              class="btn"
              @click="edit(item, index)"
              v-if="clientType == 'add' || clientType == 'edit'"
            >
              修改
            </p>
            <p
              class="btn2"
              @click="del(item, index)"
              v-if="clientType == 'add' || clientType == 'edit'"
            >
              删除
            </p>
          </div>
          <div class="row">
            <p class="label">联系人方式：</p>
            <p class="val">{{ item.mobile }}</p>
          </div>
          <div class="row">
            <p class="label">公司职务：</p>
            <p class="val">{{ item.position }}</p>
          </div>
        </div>
        <div
          class="add-box"
          @click="add"
          v-if="(addBoxStatus && clientType == 'add') || clientType == 'edit'"
        >
          <img src="@/assets/images/xmgl/wdkh-icon.png" alt="" />
          <p>添加联系人</p>
        </div>
      </div>
      <div class="form-box" v-show="tableVal == '案件信息'">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column
            prop="name"
            label="任务名称"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="agent"
            label="负责人"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="content"
            label="任务内容"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="totalDuration"
            label="服务时长（分钟）"
            align="left"
          >
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            align="left"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table>
        <!-- <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 20]"
          :page-size="interPage"
          layout="prev, pager, next, jumper"
          :total="dataSize"
          background
        ></el-pagination> -->
      </div>
      <div class="file-box" v-show="tableVal == '客户文件'">
        <!-- :class="isFileIdx === index ? 'is-file-card':''" -->
        <div
          class="file-card"
          :class="isFileIdx === index ? 'is-file-card' : ''"
          @mouseenter="mouseOver(index)"
          @mouseleave="mouseLeave"
          v-for="(item, index) in fileList"
          :key="item.name"
        >
          <!-- {{item.name.split('.')}} -->
          <!-- src="@/assets/images/xmgl/word.png" -->
          <img :src="selectImg(item.name)" alt="" />
          <p :title="item.name">{{ item.name }}</p>
          <el-progress
            v-if="progressFlag && index == fileList.length - 1"
            :percentage="loadProgress"
          ></el-progress>
          <div class="shadow-box" v-else>
            <button @click="download(item)">下载</button>
            <button
              @click="delFile(item, index)"
              v-if="clientType == 'add' || clientType == 'edit'"
            >
              删除
            </button>
          </div>
        </div>
        <div
          class="add-box"
          v-if="(clientType == 'add' || clientType == 'edit') && !progressFlag"
        >
          <!-- <input class="inputFile" ref="file" type="file" @change="outClick" /> -->
          <el-upload
            class="avatar-uploader"
            :action="objData.host"
            :data="objData"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :on-progress="uploadVideoProcess"
          >
            <img src="@/assets/images/xmgl/wdkh-icon.png" alt="" />
            <p>上传文件</p>
          </el-upload>
        </div>
        <!-- <div class="btns-box">
          <button class="btn" @click="cancel">取消</button>
          <button class="btn2">保存</button>
        </div> -->
      </div>
      <div class="file-box" v-show="tableVal == '常年法律顾问'">
        <div class="time-box">
          <el-button class="year-btn" @click="clickYearServe">{{yearServeStatus ? '关闭常年服务':'开启常年服务'}}</el-button>
          <!-- <p>
            <span style="color: red;">*</span>
            服务时间范围：
          </p> -->
          <!-- <el-date-picker
            v-model="dateVal"
            :clearable="false"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker> -->
          <p style="margin-right: 20px;" v-show="dateVal.length != 0">
            服务时间：
            <span>{{dateVal[0]}}</span>
            至
            <span>{{dateVal[1]}}</span>
          </p>
        </div>
        <div class="el-row-box" v-show="dateVal.length != 0">
          <el-row>
            <el-col :span="6">服务类型</el-col>
            <el-col :span="6">服务量</el-col>
            <el-col :span="6">已使用服务量</el-col>
            <el-col :span="6">启用状态</el-col>
          </el-row>
          <div class="rows">
            <el-row v-for="(item3,index3) in assignmentList" :key="index3">
              <el-col :span="6">{{item3.assignmentTypeName}}</el-col>
              <el-col :span="6">
                <input v-model="item3.amount" @blur="verify(item3.amount,index3)" :disabled="!item3.isEnable"/> &nbsp;&nbsp;次
              </el-col>
              <el-col :span="6">{{item3.usedCount}}次</el-col>
              <el-col :span="6">
                <el-switch
                  v-model="item3.isEnable"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :disabled="!yearServeStatus"
                >
                </el-switch>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="btns-box" v-if="tableVal != '案件信息'">
        <button class="btn" @click="cancel">取 消</button>
        <button
          class="btn2"
          @click="submit"
          v-if="clientType == 'add' || clientType == 'edit'"
        >
          保 存
        </button>
        <button class="btn2" @click="checkEdit" v-else>修 改</button>
      </div>
    </div>
    <addContactsDialog
      v-if="showDialog"
      :info="dialigInfo"
      @setContacts="setContacts"
    ></addContactsDialog>
    <openYearServeDialog v-if="showServeDialog" @onYearServe="onYearServe" :date="dateVal"></openYearServeDialog>
  </div>
</template>

<script>
import addContactsDialog from "@/views/Dialog/addContactsDialog.vue";
import openYearServeDialog from "@/views/Dialog/openYearServeDialog.vue";
import { getPolicyToken, getAddress, getFileUrl } from "@/FackApi/api/index.js";
import {
  addCustomer,
  editCustomer,
  getCustomerDetail,
  delContactCustomer,
  getPerennialLegalAdvisorPackage,
} from "@/FackApi/api/project.js";
import { mapGetters } from "vuex";
var OSS = require("ali-oss");
export default {
  name: "addTask",
  components: {
    addContactsDialog,
    openYearServeDialog,
  },
  props: {
    info: {
      type: Object,
    },
    stepShow: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        name: {
          status: false,
          val: "",
        },
        phone: {
          status: false,
          val: "",
        },
        code: {
          status: false,
          val: "",
        },
        email: {
          status: false,
          val: "",
        },
        address: {
          status: false,
          val: "",
        },
        type: {
          status: false,
          val: "",
        },
        industry: {
          status: false,
          val: "",
        },
        nature: {
          status: false,
          val: "",
        },
        label: {
          status: false,
          val: [],
        },
      },
      labelList: [
        //客户标签数组
        { label: "年度顾问", value: "年度顾问" },
        { label: "计时顾问", value: "计时顾问" },
        { label: "案件代理", value: "案件代理" },
        { label: "专项服务", value: "专项服务" },
      ],
      natureList: this.$getNature(), // 客户性质数组
      typeList: [
        { label: "个人", value: 1 },
        { label: "企业", value: 2 },
      ],
      tableList: ["联系人", "案件信息", "客户文件", "常年法律顾问"],
      tableVal: "联系人",
      showDialog: false,
      dialigInfo: {
        type: "add",
      },
      contacts: [], // 联系人数组
      clientType: "add", // 客户详情状态
      delIdList: [], // 删除的联系人id
      examineInfo: {}, // 查看客户详情数据
      industryList: this.$getIndustry(), // 所属行业数组
      addBoxStatus: true, // 添加联系人盒子显示状态
      currentPage: 1,
      interPage: 10,
      dataSize: 0,
      tableData: [], //案件信息
      fileList: [], // 上传的文件
      delFileList: [], // 删除的文件暂存数组
      isFileIdx: "", // 鼠标悬浮哪个文件上
      filePathList: [], // 文件上传成功的路径
      ossInfo: {
        // oss参数
        expireTime: 120,
        uploadDir: "project/client/file/",
      },
      objData: {
        OSSAccessKeyId: "",
        policy: "",
        Signature: "",
        key: "",
        host: "*",
        dir: "",
      },
      imageUrl: "",
      // provinceCityList: [], // 省市数据
      // value8: '',
      provinceList: [], // 省下拉框数据
      cityInfo: {}, // 市下拉框数据
      provinceVal: "", //省下拉框选中的数据
      cityVal: "", //市下拉框选中的数据
      provinceCityInfo: {}, // 选中的省市具体数据
      loadProgress: 0, // 动态显示进度条
      progressFlag: false, // 关闭进度条
      throttleNum: 0,
      flag: null, // 计时器
      // radio: 2, // 是否常年服务报告
      value: true,
      dateVal: [], // 服务时间范围
      assignmentList: [], // 常年法律顾问套餐默认列表
      assignmentInfo: {}, // 编辑返回常年法律顾问套餐数据
      showServeDialog: false, // 常年服务弹框显示
      yearServeStatus: false, // 常年服务开启状态
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "index/userInfoState",
      ossInfo2: "index/ossInfoState",
    }),
  },
  created() {
    // console.log(this.info);
    this.clientType = this.info.type;
    if (this.clientType == "add") {
      if (this.info.tableType == 1) {
        this.tableList.splice(0, 2);
        this.tableVal = "客户文件";
        this.addBoxStatus = false;
      } else {
        this.tableList.splice(1, 1);
      }
    } else if (this.clientType == "examine") {
      this.initExamine();
    }
    this.getAddressInfo();
  },
  mounted() {
    if (this.stepShow) {
      this.form.type.val = 2;
      this.form.type.status = true;
    }
  },
  methods: {
    async initExamine() {
      // console.log(this.info.info);
      let res = await getCustomerDetail(this.info.info.id);
      if (res.code == 0) {
        // console.log(res);
        let data = res.item;
        this.examineInfo = data;
        this.contacts = data.contacts;
        this.tableData = data.cases;
        this.fileList = data.files;
        this.fileList.map((item) => {
          return (item.fileName = item.name.split("."));
        });
        this.form = {
          name: {
            status: true,
            val: data.name,
          },
          phone: {
            status: true,
            val: data.contact,
          },
          type: {
            status: true,
            val: data.type,
          },
          industry: {
            status: true,
            val: data.industry,
          },
          code: {
            status: true,
            val: data.code,
          },
          email: {
            status: true,
            val: data.email,
          },
          nature: {
            status: true,
            val: data.nature,
          },
          label: {
            status: true,
            // val: data.tags ? data.tags.split(",") : "",
            val: "",
          },
          address: {
            status: true,
            val: data.address,
          },
        };
        // console.log(this.form);
        if (this.form.type.val == 1) {
          this.tableList.splice(0, 1);
        }
        this.provinceVal = data.province;
        this.cityVal = data.city;
        this.provinceCityInfo = {
          provinceId: data.provinceId,
          province: data.province,
          city: data.city,
          cityId: data.cityId,
        };
        this.yearServeStatus = data.isEnablePerennialLegalAdvisorPackage;
        if (data.perennialLegalAdvisorPackage) {
          this.assignmentInfo = data.perennialLegalAdvisorPackage;
          let startDate = this.assignmentInfo.startDate.split(" ")[0];
          let endDate = this.assignmentInfo.endDate.split(" ")[0];
          this.dateVal = [startDate,endDate]
          this.assignmentList = this.assignmentInfo.details;
        } else {
          // this.getServiceContent();
        }
      }
      this.checkEdit();
    },
    async getAddressInfo() {
      let res = await getAddress();
      // console.log(res);
      if (res.code == 0) {
        // this.provinceCityList = res.items;
        res.items.map((item) => {
          this.provinceList.push({
            id: item.id,
            label: item.name,
            value: item.name,
          });
          this.cityInfo[item.name] = item.cities;
        });
      }
    },
    // 获取常年法律顾问套餐  getPerennialLegalAdvisorPackage
    async getServiceContent() {
      let res = await getPerennialLegalAdvisorPackage();
      // console.log(res,'顾问套餐');
      if(res.code == 0) {
        this.assignmentList = [];
        res.items.forEach(item => {
          this.assignmentList.push(
            {
              amount: item.amount,
              assignmentTypeId: item.assignmentTypeId,
              assignmentTypeName: item.assignmentTypeName,
              isEnable: item.isEnable,
              usedCount: 0,
            }
          )
        });
      }
    },
    // 点击常年服务
    clickYearServe() {
      if (!this.yearServeStatus) {
        this.showServeDialog = true;
      } else {
        this.$confirm('是否确认关闭常年服务?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.assignmentList.forEach(item => {
            item.isEnable = false;
          })
          this.yearServeStatus = false;
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消关闭'
          });          
        });
      }
    },
    onYearServe(info) {
      this.showServeDialog = false;
      this.yearServeStatus = true;
      if (this.dateVal.length == 0) {
        this.getServiceContent();
      }
      this.dateVal = [
        this.$getDate(1,info.dateVal[0]),this.$getDate(1,info.dateVal[1])
      ]
      setTimeout(()=>{
        this.assignmentList.forEach(item => {
            item.isEnable = true;
          })
      },300)
      console.log(this.yearServeStatus);
    },
    // 服务次数输入框失焦
    verify(val,idx) {
      // console.log(val,idx);
      var reg = /^([0]|[1-9][0-9]*)$/;
      if (!reg.test(val)) {
        this.assignmentList[idx].amount = "";
        this.$message.warning("服务量只能为正整数!");
        return;
      } else {
        this.assignmentList[idx].amount = Number(val);
      }
    },
    // 选择省
    selectProvince() {
      // console.log(this.provinceList);
      for (var i = 0; i < this.provinceList.length; i++) {
        let newItem = this.provinceList[i];
        if (newItem.label == this.provinceVal) {
          // console.log(this.cityInfo[this.provinceVal]);
          this.provinceCityInfo.provinceId = newItem.id;
          this.provinceCityInfo.province = newItem.value;
          this.provinceCityInfo.cityId = this.cityInfo[this.provinceVal][0].id;
          this.provinceCityInfo.city = this.cityInfo[this.provinceVal][0].name;
          this.cityVal = this.cityInfo[this.provinceVal][0].name;
          break;
        }
      }
      // console.log(this.provinceCityInfo);
    },
    // 选择市
    selectCity() {
      let newArr = this.cityInfo[this.provinceVal];
      for (var i = 0; i < newArr.length; i++) {
        let newItem = newArr[i];
        if (newItem.name == this.cityVal) {
          this.provinceCityInfo.cityId = newItem.id;
          this.provinceCityInfo.city = newItem.name;
          break;
        }
      }
      // console.log(this.provinceCityInfo);
    },
    sheckTable(val) {
      this.tableVal = val;
    },
    // 编辑客户
    checkEdit() {
      this.clientType = "edit";
      this.form.name.status = false;
      this.form.phone.status = false;
      this.form.address.status = false;
      this.form.email.status = false;
      this.form.nature.status = false;
      this.form.label.status = false;
    },
    // 删除联系人
    del(itm, idx) {
      this.contacts.splice(idx, 1);
      this.delIdList.push(itm.id);
      // console.log(this.delIdList);
    },
    // 新增联系人
    add() {
      this.dialigInfo.type = "add";
      this.showDialog = true;
    },
    // 编辑联系人
    edit(item, index) {
      // console.log(item);
      this.dialigInfo.type = "edit";
      this.dialigInfo.item = item;
      this.dialigInfo.idx = index;
      this.showDialog = true;
    },
    // 联系人弹框保存
    setContacts(obj) {
      if (obj.type == "add") {
        this.contacts.push({
          name: obj.info.name,
          mobile: obj.info.phone,
          email: obj.info.email,
          position: obj.info.job,
        });
      } else {
        let newObj = {
          id: obj.info.id,
          name: obj.info.name,
          mobile: obj.info.phone,
          email: obj.info.email,
          position: obj.info.job,
        };
        this.contacts[obj.idx] = newObj;
      }
    },
    // 选择客户类型
    selectType() {
      // console.log(this.form.type.val);
      if (this.form.type.val == 2) {
        this.tableList = ["联系人", "客户文件", "常年法律顾问"];
        this.tableVal = "联系人";
        this.addBoxStatus = true;
      } else {
        this.tableList = ["客户文件"];
        this.tableVal = "客户文件";
        this.addBoxStatus = false;
        // this.filePathList = [];
        // this.fileList = [];
      }
    },
    // 上传客户文件前的操作
    async beforeAvatarUpload(file) {
      let newArr = file.name.split(".");
      let fileType = newArr[newArr.length - 1];
      // console.log(fileType);
      // console.log(file,file.type);return
      let imgType = [
        "doc",
        "docx", // Word
        "xls",
        "xlsx", // Excel
        "ppt", // PPT
        "pdf", // PDF
        "jpg",
        "jpeg",
        "dds",
        "psd",
        "pdt",
        "webp",
        "xmp",
        "gif",
        "bmp",
        "svg",
        "tiff",
        "ico", // 图片
        "asx",
        "asf",
        "wmv",
        "rmvb",
        "rm",
        "mp4",
        "3gp",
        "m4v",
        "mov",
        "avi",
        "dat",
        "mkv",
        "flv",
        "vob", // 视频
        "mp3",
        "wma",
        "aac",
        "ogg",
        "mpc",
        "flac",
        "ape",
        "wv",
        "ncm",
        "ogg",
        "mflac",
        "kgm",
        "xm", // 音频
        "rar",
        "7z",
        "zip",
        "ar",
        "bz",
        "car",
        "dar",
        "cpgz",
        "ha", // 压缩包
      ];
      const isLt5M = file.size / 1024 / 1024 < 500;
      if (!imgType.includes(fileType)) {
        this.$message.warning(
          "上传文件只能是 Word、Excel、PPT、PDF、图片、视频、音频以及压缩包!"
        );
        return;
      }
      if (!isLt5M) {
        this.$message.warning("上传文件大小不能超过 500MB!");
        return;
      }
      let res3 = await getPolicyToken(this.ossInfo);
      if (res3.code == 0) {
        // console.log(res3);
        let data = JSON.parse(res3.content);
        this.objData = {
          OSSAccessKeyId: data.accessid,
          policy: data.policy,
          signature: data.signature,
          expire: data.expire,
          host: data.host,
          dir: data.dir,
          key: `project/client/file/${
            this.userInfo.userId
          }/${new Date().getTime()}-${file.name}`,
        };
        this.filePathList.push(this.objData.key);
        // console.log(this.objData);
        this.fileList.push({
          name: file.name,
          filePath: this.objData.key,
        });
      } else {
        return;
      }
    },
    // 上传文件进度
    uploadVideoProcess(event, file, fileList) {
      // console.log(event.percent);
      this.progressFlag = true; // 显示进度条
      this.loadProgress = parseInt(event.percent); // 动态获取文件上传进度
      if (this.loadProgress >= 100) {
        this.loadProgress = 100;
        setTimeout(() => {
          this.progressFlag = false;
        }, 1000); // 一秒后关闭进度条
      }
    },
    // 设置文件图片
    selectImg(info) {
      // console.log(info);
      let imgUrl = "";
      let valList = info.split(".");
      let fileType = valList[valList.length - 1];
      let imgType = [
        "jpg",
        "jpeg",
        "dds",
        "psd",
        "pdt",
        "webp",
        "xmp",
        "gif",
        "bmp",
        "svg",
        "tiff",
        "ico",
      ];
      let videoType = [
        "asx",
        "asf",
        "wmv",
        "rmvb",
        "rm",
        "mp4",
        "3gp",
        "m4v",
        "mov",
        "avi",
        "dat",
        "mkv",
        "flv",
        "vob",
      ];
      let audioType = [
        "mp3",
        "wma",
        "aac",
        "ogg",
        "mpc",
        "flac",
        "ape",
        "wv",
        "ncm",
        "ogg",
        "mflac",
        "kgm",
        "xm",
      ];
      let rarType = [
        "rar",
        "7z",
        "zip",
        "ar",
        "bz",
        "car",
        "dar",
        "cpgz",
        "ha",
      ];
      if (fileType == "doc" || fileType == "docx") {
        imgUrl = require("@/assets/images/xmgl/file-word.png");
      } else if (fileType == "xls" || fileType == "xlsx") {
        imgUrl = require("@/assets/images/xmgl/file-excel.png");
      } else if (fileType == "ppt") {
        imgUrl = require("@/assets/images/xmgl/file-ppt.png");
      } else if (fileType == "pdf") {
        imgUrl = require("@/assets/images/xmgl/file-pdf.png");
      } else if (imgType.includes(fileType)) {
        imgUrl = require("@/assets/images/xmgl/file-img.png");
      } else if (videoType.includes(fileType)) {
        imgUrl = require("@/assets/images/xmgl/file-video.png");
      } else if (audioType.includes(fileType)) {
        imgUrl = require("@/assets/images/xmgl/file-audio.png");
      } else if (rarType.includes(fileType)) {
        imgUrl = require("@/assets/images/xmgl/file-rar.png");
      }
      return imgUrl;
    },
    // 鼠标悬浮文件上
    mouseOver(idx) {
      this.isFileIdx = idx;
    },
    // 离开文件
    mouseLeave() {
      this.isFileIdx = "";
    },
    // 下载客户文件
    async download(item) {
      let params = {
        expireTime: 3600,
        objectName: item.filePath,
      };
      let ress = await getFileUrl(params);
      fetch(ress).then(res => res.blob()).then(blob => {
        const a = document.createElement('a');
        document.body.appendChild(a)
        a.style.display = 'none'
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        // 指定下载的文件名
        a.download = item.name;
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      return;
      let client = new OSS({
        region: "oss-cn-shenzhen",
        accessKeyId: this.ossInfo2.accessid,
        accessKeySecret: "5ZUDWAJ0Gz4o52kubRVYCaJRRQRims",
        bucket: "ilaas-files",
      });
      let filename = item.name;
      let response = {
        "content-disposition": `attachment; filename=${encodeURIComponent(
          filename
        )};expires: 7200`,
      };
      let url = client.signatureUrl(item.filePath, {
        response,
      });
      window.location.href = url;
    },
    // 删除客户文件
    delFile(item, idx) {
      this.$confirm(`请确认是否删除文件【 ${item.name} 】`)
        .then((_) => {
          this.fileList.splice(idx, 1);
          // this.delFileList.push(item.filePath);
          this.ossDelFile(item);
        })
        .catch((_) => {});
    },
    ossDelFile(item) {
      let client = new OSS({
        region: "oss-cn-shenzhen",
        accessKeyId: this.ossInfo2.accessid,
        accessKeySecret: "5ZUDWAJ0Gz4o52kubRVYCaJRRQRims",
        bucket: "ilaas-files",
      });
      client.delete(item.filePath);
    },
    outClick() {},
    upFile() {
      document.querySelector(".inputFile").click();
    },
    // 案件信息翻页
    handleSizeChange(psize) {
      this.interPage = psize;
      // this.getTaskListInfo();
    },
    handleCurrentChange(cpage) {
      this.currentPage = cpage;
      // this.getTaskListInfo();
    },
    submit() {
      if (this.clientType == "add") {
        this.addSubmit();
      } else {
        this.editSubmit();
      }
    },
    // 新增提交
    async addSubmit() {
      if (this.throttleNum != 0) return;
      this.throttleNum = 1;
      let that = this;
      this.flag = setInterval(() => {
        that.throttleNum++;
        if (that.throttleNum == 2) {
          that.throttleNum = 0;
          clearInterval(this.flag);
        }
      }, 1000);
      let verifyObj = {
        name: "客户名称",
        phone: "联系方式",
        type: "客户类型",
        address: "详细地址",
        // label: "客户标签",
      };
      for (let key in verifyObj) {
        if (!this.form[key].val) {
          this.$message.warning(`${verifyObj[key]}不能为空！`);
          return;
        }
      }
      let isPhone = /^1[3456789]\d{9}$/;
      var isMob = /^((0\d{2,3})-)?(\d{7,8})$/;
      if (
        isPhone.test(this.form.phone.val) ||
        isMob.test(this.form.phone.val)
      ) {
        // let phoneReg = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/;
        // if (!phoneReg.test(this.form.phone.val)) {
        //     this.$message.warning(`请正确输入联系方式（手机号或座机号）！`);
        //       return;
        //   }
        if (this.form.email.val) {
          var emailReg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;
          if (!emailReg.test(this.form.email.val)) {
            this.$message.warning(`请正确输入邮箱！`);
            return;
          }
        }
        if (!this.cityVal) {
          this.$message.warning(`所在城市不能为空！`);
          return;
        }
        // if (this.form.label.val.length == 0) {
        //   this.$message.warning(`客户标签不能为空！`);
        //   return;
        // }
        if (this.form.type.val == 2) {
          if (!this.form.industry.val) {
            this.$message.warning(`所属行业不能为空！`);
            return;
          }
          if (!this.form.nature.val) {
            this.$message.warning(`客户性质不能为空！`);
            return;
          }
        }
        // if(this.delFileList.length != 0) {
        //   let client = new OSS({
        //     region: 'oss-cn-shenzhen',
        //     accessKeyId: this.ossInfo2.accessid,
        //     accessKeySecret: '5ZUDWAJ0Gz4o52kubRVYCaJRRQRims',
        //     bucket: 'ilaas-files',
        //   })
        //   for (let i = 0; i < this.delFileList.length; i++) {
        //     let item = this.delFileList[i]
        //     client.delete(item);
        //   }
        // }  
        let params = {
          code: this.form.code.val,
          name: this.form.name.val,
          type: this.form.type.val,
          address: this.form.address.val,
          email: this.form.email.val,
          nature: this.form.type.val == 1 ? "" : this.form.nature.val,
          // tags: this.form.label.val.join(","),
          tags: "",
          provinceId: this.provinceCityInfo.provinceId,
          province: this.provinceCityInfo.province,
          city: this.provinceCityInfo.city,
          cityId: this.provinceCityInfo.cityId,
          contact: this.form.phone.val,
          industry: this.form.type.val == 1 ? "" : this.form.industry.val,
          remark: "",
          contacts: this.form.type.val == 1 ? [] : this.contacts,
          files: this.fileList,
          isEnablePerennialLegalAdvisorPackage: this.yearServeStatus,
          // perennialLegalAdvisorPackage: {
          //   startDate: this.$getDate(2,this.dateVal[0]),
          //   endDate: this.$getDate(2,this.dateVal[1]),
          //   details: this.assignmentList,
          // }
        };
        if (this.dateVal.length == 0) {
          params.perennialLegalAdvisorPackage = null;
        } else {
          params.perennialLegalAdvisorPackage = {
            startDate: this.dateVal[0] ,
            endDate: this.dateVal[1],
            details: this.assignmentList,
          };
        }
        // console.log(params);return
        let res = await addCustomer(params);
        if (res.code == 0) {
          this.$parent.showTable = true;
          this.$parent.step = 1;
          this.$parent.getCustomerInfo();
        }
      } else {
        this.$message.warning(`请正确输入联系方式（手机号或座机号）！`);
      }
    },
    // 编辑提交
    async editSubmit() {
      if (this.throttleNum != 0) return;
      this.throttleNum = 1;
      let that = this;
      this.flag = setInterval(() => {
        that.throttleNum++;
        if (that.throttleNum == 2) {
          that.throttleNum = 0;
          clearInterval(this.flag);
        }
      }, 1000);
      let verifyObj = {
        name: "客户名称",
        phone: "联系方式",
        address: "详细地址",
        // label: "客户标签",
      };
      for (let key in verifyObj) {
        if (!this.form[key].val) {
          this.$message.warning(`${verifyObj[key]}不能为空！`);
          return;
        }
      }
      let isPhone = /^1[3456789]\d{9}$/;
      var isMob = /^((0\d{2,3})-)?(\d{7,8})$/;
      if (
        isPhone.test(this.form.phone.val) ||
        isMob.test(this.form.phone.val)
      ) {
        if (this.form.email.val) {
          var emailReg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;
          if (!emailReg.test(this.form.email.val)) {
            this.$message.warning(`请正确输入邮箱！`);
            return;
          }
        }
        if (!this.cityVal) {
          this.$message.warning(`所在城市不能为空！`);
          return;
        }
        // if (this.form.label.val.length == 0) {
        //   this.$message.warning(`客户标签不能为空！`);
        //   return;
        // }
        if (this.form.type.val == 2) {
          if (!this.form.nature.val) {
            this.$message.warning(`客户性质不能为空！`);
            return;
          }
        }
        if (this.delIdList.length != 0) {
          // 删除联系人
          let res2 = await delContactCustomer({ ids: this.delIdList });
          // console.log(res2);
          if (res2.code != 0) return;
        }
        // if(this.delFileList.length != 0) {
        //   const client = new OSS({
        //     region: 'oss-cn-shenzhen',
        //     accessKeyId: this.ossInfo2.accessid,
        //     accessKeySecret: '5ZUDWAJ0Gz4o52kubRVYCaJRRQRims',
        //     bucket: 'ilaas-files',
        //   })
        //   for (let i = 0; i < this.delFileList.length; i++) {
        //     let item = this.delFileList[i]
        //     client.delete(item).then(res =>{
        //     });
        //   }
        // }
        let params = this.examineInfo;
        params.name = this.form.name.val;
        params.address = this.form.address.val;
        params.contact = this.form.phone.val;
        params.contacts = this.contacts;
        params.provinceId = this.provinceCityInfo.provinceId;
        params.province = this.provinceCityInfo.province;
        params.city = this.provinceCityInfo.city;
        params.cityId = this.provinceCityInfo.cityId;
        params.email = this.form.email.val;
        params.nature = this.form.type.val == 1 ? "" : this.form.nature.val;
        // params.tags = this.form.label.val.join(",");
        params.tags = "";
        params.industry = this.form.type.val == 1 ? "" : this.form.industry.val;
        params.remark = "";
        params.contacts = this.form.type.val == 1 ? [] : this.contacts;
        params.files = this.fileList;
        params.isEnablePerennialLegalAdvisorPackage = this.yearServeStatus;
        // params.perennialLegalAdvisorPackage = {
        //   startDate: this.$getDate(2,this.dateVal[0]),
        //   endDate: this.$getDate(2,this.dateVal[1]),
        //   details: this.assignmentList,
        // }
        if (this.assignmentInfo.id) {
          this.assignmentInfo.startDate = this.dateVal[0];
          this.assignmentInfo.endDate = this.dateVal[1];
          params.perennialLegalAdvisorPackage = this.assignmentInfo;
        } else {
          if (this.dateVal.length == 0) {
            params.perennialLegalAdvisorPackage = null;
          } else {
            params.perennialLegalAdvisorPackage = {
              startDate: this.dateVal[0],
              endDate: this.dateVal[1],
              details: this.assignmentList,
            }
          }
        }
        // params.files = this.form.type.val == 1 ? [] : this.fileList;
        // console.log(params);return
        let res3 = await editCustomer(params);
        if (res3.code == 0) {
          this.$parent.getCustomerInfo();
          this.cancel();
        }
      } else {
        this.$message.warning(`请正确输入联系方式（手机号或座机号）！`);
      }
    },
    // 返回
    cancel() {
      this.$parent.step = 1;
      this.$parent.showTable = true;
    },
  },
  beforeDestroy() {
    clearInterval(this.flag);
  },
};
</script>

<style lang="less" scoped>
.add-task {
  .client-box {
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 0.2);
    border-radius: 18px;
    padding: 30px 30px 10px 30px;
    margin: 10px 15px 20px;
    box-sizing: border-box;
    .all-crumbs {
      margin-bottom: 20px !important;
    }
    .row-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 14px;
      .col-box {
        display: flex;
        align-items: center;
        width: 46%;
        .label {
          min-width: 72px;
          font-size: 14px;
          color: #909399;
          margin-right: 0;
          white-space: nowrap;
          span {
            color: red;
          }
        }
        .el-input {
          flex: 1;
          height: 40px;
          font-size: 14px;
          background: #fff;
          border-radius: 10px;
          box-sizing: border-box;
          input {
            height: 100%;
            width: 100%;
            border-radius: 10px;
          }
        }
        /deep/ .el-select {
          flex: 1;
          .el-input {
            .el-input__inner {
              font-size: 14px;
            }
          }
        }
        .city-box {
          display: flex;
          justify-content: space-between;
          flex: 1;
          .el-select {
            flex: none;
            width: 49%;
            // margin-right: 10px;
            // .el-input {
            //   .el-input__inner {
            //     width: 160px;
            //   }
            // }
          }
        }
        .el-radio-group {
          display: flex;
          align-items: center;
          label {
            margin-left: 4px;
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
  .tabel-box {
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 0.2);
    border-radius: 14px;
    padding: 30px 30px 10px 30px;
    margin: 30px 15px 0;
    box-sizing: border-box;
    .table {
      display: flex;
      margin-bottom: 10px;
      p {
        font-size: 14px;
        font-weight: bold;
        color: #909399;
        margin-right: 60px;
        cursor: pointer;
      }
      .in-p {
        color: #837af3;
      }
    }
    .cards-box {
      display: flex;
      flex-wrap: wrap;
      height: 242px;
      overflow-y: auto;
      .card {
        width: 24%;
        height: 152px;
        padding: 16px 0 6px;
        border-radius: 10px;
        border: 1px solid #d7dbda;
        margin-right: 1%;
        margin-bottom: 14px;
        .row {
          display: flex;
          align-items: center;
          margin: 0 16px;
          margin-bottom: 10px;
          .label {
            font-size: 14px;
            color: #909399;
          }
          .val {
            flex: 1;
            font-size: 14px;
            color: #333;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .btn {
            font-size: 16px;
            color: #6474c7;
            cursor: pointer;
          }
          .btn2 {
            font-size: 16px;
            color: #fcc647;
            cursor: pointer;
          }
        }
      }
      .add-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 24%;
        height: 152px;
        margin-right: 1%;
        margin-bottom: 14px;
        border-radius: 10px;
        background: #f0f3fb;
        cursor: pointer;
        img {
          width: 30px;
          height: 30px;
          margin-bottom: 10px;
        }
        p {
          font-size: 16px;
          color: #6474c7;
        }
      }
    }

    .form-box {
      /deep/ .el-table {
        border-radius: 18px 18px 0 0;
        .el-table__header-wrapper {
          .el-table__header {
            .has-gutter {
              th {
                font-size: 14px;
                font-weight: bold;
                color: #6474c7;
                padding: 12px 16px;
                background: #eaf5ff;
              }
            }
          }
        }
        .el-table__body-wrapper {
          height: 350px;
          .el-table__body {
            td {
              font-size: 14px;
              color: #333;
              padding: 12px 16px;
              .cell {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
    .el-pagination {
      align-items: initial;
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }

    .file-box {
      display: flex;
      flex-wrap: wrap;
      height: 242px;
      overflow-y: auto;
      .file-card {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 13%;
        height: 200px;
        border-radius: 18px;
        border: 1px solid #dddfe6;
        padding-top: 30px;
        margin-right: 1.1%;
        margin-bottom: 16px;
        p {
          width: 90%;
          font-size: 14px;
          text-align: center;
          color: #333;
          margin-top: 30px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .shadow-box {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 200px;
          color: #fff;
          background-color: rgba(63, 65, 77, 0.5);
          z-index: -1;
          border-radius: 18px;
          overflow: hidden;
        }
        .el-progress {
          width: 80%;
        }
      }
      .is-file-card {
        .shadow-box {
          z-index: 9;
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            width: 60px;
            height: 30px;
            line-height: 30px;
            border-radius: 15px;
            border: none;
            background-color: #fff;
            color: #333;
            font-size: 14px;
            margin: 0 5px;
          }
        }
      }
      /deep/ .add-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 13%;
        height: 200px;
        margin-right: 1.1%;
        margin-bottom: 14px;
        border-radius: 18px;
        background: #f0f3fb;
        cursor: pointer;
        img {
          width: 30px;
          height: 30px;
          margin: 70px 0 10px;
        }
        p {
          font-size: 16px;
          color: #6474c7;
        }
        .avatar-uploader {
          width: 100%;
          height: 100%;
          .el-upload--text {
            width: 100%;
            height: 100%;
            .avatar-uploader-icon {
              display: flex;
              flex-direction: column;
              margin: 70px auto;
              font-size: 26px;
              font-weight: 600;
              color: #6474c7;
              span {
                font-size: 16px;
                font-weight: 400;
                margin-top: 10px;
              }
            }
          }
        }
      }
      .time-box {
        display: flex;
        // align-items: center;
        justify-content: space-between;
        width: 100%;
        // .el-date-editor {
        //   width: 23%;
        // }
        .year-btn {
          display: flex;
          align-items: center;
          width: 120px;
          height: 40px;
          border-radius: 20px;
          // line-height: 0;
          color: #697bca;
          border: 1px solid #697bca;
        }
      }
      .el-row-box {
        width: 100%;
        margin-top: 8px;
        border-top: 1px solid #ccc;
        border-left: 1px solid #ccc;
        .el-row {
          width: 100%;
          .el-col {
            text-align: center;
            padding: 4px 0;
            border-bottom: 1px solid #ccc;
            border-right: 1px solid #ccc;
          }
        }
        .rows {
          // max-height: 300px;
          // overflow-y: auto;
          input {
            width: 60px;
            height: 24px;
          }
        }
      }
    }
  }
  .btns-box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    button {
      width: 140px;
      height: 40px;
      font-size: 14px;
      font-weight: 600;
      color: #bbb;
      border-radius: 25px;
      background: #f1f2f1;
      border: none;
      margin-left: 20px;
      cursor: pointer;
    }
    .btn2 {
      color: #fff;
      background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
      border: none;
    }
  }
}
</style>
